<template>
  <div class="row">
    <div class="box box-default box-solid box-component roles-box row">
      <div class="box-body box-details-header">
        <div class="col-xs-4">
          <details-header
            :role="role"
            :service="service"
          />
        </div>
        <div class="col-xs-12">
          <action-access-tree
            :role="role.name"
            :service="service"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionAccessTree from './ActionAccessTreeCheckboxes'
import DetailsHeader from './DetailsHeader.vue'

export default {
  components: {
    DetailsHeader,
    ActionAccessTree
  },
  props: {
    role: { required: true },
    service: { type: String, required: true }
  }
}
</script>

<style scoped>
table.permissions td {
    width: 50px;
    text-align: center;
}
</style>
