<template>
  <div>
    <div class="box-header">
      <h3 class="box-title">
        Uprawnienia
      </h3>
    </div>
    <div class="role-tree">
      <checkbox-tree
        :action-accesses-tree="actionAccessesTree"
      />
    </div>
  </div>
</template>

<script>
import api from '../../../../../api/index'
import CheckboxTree from './tree/PreviewCheckboxTree'

export default {
  components: {
    CheckboxTree
  },
  props: {
    service: { type: String, required: true },
    role: { required: true }
  },
  data () {
    return {
      actionAccessesTree: []
    }
  },
  mounted () {
    this.$nextTick(() => {
      api.request(this.service, 'get', `/roles/${this.$route.params.id}/access-tree`)
        .then((response) => {
          this.actionAccessesTree = response.data
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Błąd',
            text: error.errorMessage
          })
        })
    })
  }
}
</script>
