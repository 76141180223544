<script>
import FormModalMixin from '../../../../../share/modal/FormModalMixin'
import FormModal from '../form/Role'
import api from '../../../../../../api'

export default {
  components: {
    FormModal
  },
  mixins: [
    FormModalMixin
  ],
  props: {
    service: { type: String, required: true }
  },
  data () {
    return {
      title: this.mode === 'new' ? 'Rejestracja roli' : 'Edycja roli',
      okText: this.mode === 'new' ? 'Dodaj' : 'Zmień',
      action: { new: 'roles', edit: `roles/${this.$route.params.id}` },
      method: { new: 'post', edit: 'put' },
      notifOkText: { new: 'Dodano rolę', edit: 'Zaktualizowano rolę' }
    }
  },
  methods: {
    submitForm () {
      api.request(this.service, this.method[this.mode], this.action[this.mode], this.createDataToSend())
        .then(() => {
          this.toggleLoading()
          this.$events.$emit('reloadGrid')
          this.$emit('success')
          this.$emit('close')
          this.$notify({
            type: 'success',
            text: this.notifOkText[this.mode]
          })
        })
        .catch((error) => {
          this.errorNotify(error)
        })
    },
    createDataToSend () {
      let data = {
        name: this.model.label,
        description: this.model.description
      }
      if (this.service !== 'finance') {
        data['inspectionVisibility'] = 0
      } else {
        data['customized'] = false
      }
      return data
    }
  }
}
</script>
