<script>
import BaseDetails from './Details'

export default {
  mixins: [
    BaseDetails
  ],
  data () {
    return {
      events: null,
      service: this.$route.meta.acl.service
    }
  },
  created () {
    this.getInitEvents()
  },
  mounted () {
    this.$events.on(this.events.editActionsAccess, this.editActionsAccess)
  },
  methods: {
    getInitEvents () {
      this.events = {
        editActionsAccess: `${this.service}:role:editActionsAccess`
      }
    },
    editActionsAccess () {
      this.$router.push({ name: `${this.service}_role_details_acl_edition` })
    }
  }
}
</script>

<style scoped>
table.permissions td {
    width: 50px;
    text-align: center;
}
</style>
