<template>
  <form>
    <input-type
      v-model="value.label"
      v-validate="{ rules: { required: true, regex: /^([a-zA-Z0-9ęóąśłżźćńĘÓĄŚŁŻŹĆŃ-]+\s*){3,}/ }}"
      label="Nazwa"
      name="label"
      :error-message="vErrors.first('label')"
      :required="true"
      data-vv-value-path="value"
      data-vv-as="nazwa"
    />
    <text-area-type
      v-model="value.description"
      v-validate="{rules: {required: false, max: 1024}}"
      label="Opis"
      name="description"
      placeholder="Wpisz treść... Opis nie może przekroczyć 1024 znaków."
      :error-message="vErrors.first('description')"
      :required="false"
      data-vv-value-path="value"
      data-vv-as="opis"
    />
  </form>
</template>

<script>
import FormModalValidation from '../../../../../share/form/FormModalValidation'
import InputType from '../../../../../share/form/type/InputType'
import TextAreaType from '../../../../../share/form/type/TextAreaType'

export default {
  components: {
    InputType,
    TextAreaType
  },
  mixins: [
    FormModalValidation
  ],
  props: {
    value: {}
  }

}
</script>
