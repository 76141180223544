import api from '../../../../../api'
import DynamicBreadcrumb from '../../../share/mixins/DynamicBreadcrumb'
import RoleEditionModal from './modal/Role.vue'
import Page from '../../../../share/content/Page'
import DetailsContent from './DetailsContent.vue'

export default {
  template: `
    <div>
      <section class="content">
        <details-content :role="role" :service="service"></details-content>
      </section>
      <role-edition-modal
          :show="roleEditionVisible"
          @close="roleEditionVisible = false"
          :service="service"
          mode="edit"
          :formData="role"
          @success="loadContent"
          >
      </role-edition-modal>
    </div>
  `,
  components: {
    RoleEditionModal,
    DetailsContent
  },
  mixins: [
    DynamicBreadcrumb,
    Page
  ],
  data () {
    return {
      headerTitle: { title: 'Role', description: 'Szczegóły' },
      roleEditionVisible: false,
      actionsAccessEditionVisible: false,
      actionEditName: 'role_edit',
      actionEditActionsName: 'role_edit_actions',
      urlActions: {
        getContent: { method: 'get', url: `roles/${this.$route.params.id}` }
      },
      role: {
        label: '',
        name: '',
        description: ''
      },
      actionsEdit: []
    }
  },
  mounted () {
    this.$events.on(`${this.service}:role:editRole`, this.editRole)

    this.$nextTick(() => {
      this.loadContent()
    })
  },
  methods: {
    editRole () {
      this.roleEditionVisible = true
    },
    loadContent () {
      this.toggleLoading()

      api.request(this.service,
        this.urlActions.getContent.method,
        this.urlActions.getContent.url)
        .then((response) => {
          this.toggleLoading()
          this.role = response.data

          // dynamic breadcrumb labels
          this.addDynamicBreadcrumbLabel(this.$route.name, this.role.label)
        })
        .catch(() => {
          this.toggleLoading()
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować strony'
          })
        })
    }
  }
}
